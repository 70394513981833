<template lang="">
  <div>
    <div>
      <Card>
        <template #header>Belge Kayıtları</template>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <Input placeholder="Ara.." v-model="search" class="mr-2" />
          </div>
          <div class="d-flex align-items-center">
            <b-form-group>
              <router-link to="tapuekle">
                <b-button @click="addFileModal = true" variant="primary"
                  >BELGE EKLE</b-button
                >
              </router-link>
            </b-form-group>
          </div>
        </div>
      </Card>

      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Sistem Kullanıcı Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <!-- GÖRÜNTÜLEME LİNK -->
        <template v-slot:item.goruntule="{ item }">
          <a href="#">Belgeyi Görüntüle</a>
        </template>
        <!-- SİL BUTTON -->
        <template v-slot:item.sil="{ item }">
          <b-button
            :variant="[!item.isActive ? 'outline-success' : 'outline-danger']"
            @click="editModalOptions(item)"
            class="mr-3"
            ><i class="fas fa-check p-0 fa-sm" v-if="!item.isActive"></i>
            <i class="fas fa-times p-0 fa-sm" v-else></i>
          </b-button>
          <b-button variant="light" @click="openDeleteDoc()">
            <i
              class="fas fa-trash text-danger bg-info p-0 rounded-sm fa-sm"
              style="cursor: pointer"
            ></i>
          </b-button>
          <DeleteOperation modalid="belge" />
          <b-button variant="light" class="ml-3" @click="editDocs(item)">
            <i class="fas fa-pen p-0 rounded-sm fa-sm"></i>
          </b-button>
        </template>

        <!-- BELGE EKLE MODAL -->
        <b-modal v-model="addFileModal" id="note-modal">
          <template #modal-header>
            <h4>Bekle Ekle</h4>
          </template>
          <div class="form-group">
            <label for="aciklama">Belge Adı</label>
            <input type="text" class="form-control" id="ilAdi" />
          </div>
          <div class="form-group">
            <label for="aciklama">Belge Tipi</label>
            <input type="text" class="form-control" id="ilPlakaKod" />
          </div>
          <div class="form-group">
            <b-form-group label-size="sm">
              <b-form-file id="file-small" size="sm"></b-form-file>
            </b-form-group>
          </div>
          <template #modal-footer>
            <div class="d-flex justify-content-end w-100">
              <b-button variant="light" @click="addFileModal = false">
                Vazgeç
              </b-button>
              <b-button variant="success" @click="addNewNote" class="ml-4">
                Kaydet
              </b-button>
            </div>
          </template>
        </b-modal>

        <!-- BELGE DÜZENLE MODAL 1 -->
        <b-modal v-model="addFileModal1" id="note-modal1">
          <template #modal-header>
            <h4>Belge Düzenle</h4>
          </template>
          <div class="form-group">
            <label for="aciklama">Belge Adı</label>
            <input
              type="text"
              class="form-control"
              id="ilAdi"
              :value="editDocItem.belgeAd"
            />
          </div>
          <div class="form-group">
            <label for="aciklama">Belge Tipi</label>
            <input type="text" class="form-control" id="ilPlakaKod" />
          </div>
          <div class="form-group">
            <b-form-group label-size="sm">
              <b-form-file id="file-small" size="sm"></b-form-file>
            </b-form-group>
          </div>
          <template #modal-footer>
            <div class="d-flex justify-content-end w-100">
              <b-button variant="light" @click="addFileModal1 = false">
                Vazgeç
              </b-button>
              <b-button variant="success" @click="addNewNote" class="ml-4">
                Kaydet
              </b-button>
            </div>
          </template>
        </b-modal>
      </List>
      <!-- Create and Actions Popups -->
    </div>
  </div>
</template>
<script>
import DeleteOperation from "../../global/modals/DeleteOperation.vue";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  components: {
    DeleteOperation: DeleteOperation,
    },
  name: "systemUsers",
  data() {
    return {
      addFileModal: false,
      addFileModal1: false,
      editDocItem: {},
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Belge Adı", value: "belgeAd" },
        { text: "Belge Tipi", value: "belgeTip" },
        { text: "Görüntüle", value: "goruntule" },
        { text: "İşlemler", value: "sil" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
    };
  },
  props: {
    url: String,
    params: Array | String,
    modalid: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

      this.items = [
        {
          belgeAd: "Deneme Belge 1",
          belgeTip: "Resmi",
          goruntule: "",
          sil: "",
          edit: "",
          isActive: 0,
        },
      ];
      this.totalItems = 3;

      this.pagination = {
        current_page: 1,
        total_pages: 1,
        total_items: 3,
      };
      this.loading = false;

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    editModalOptions(item) {
      Swal.fire({
        title: "Emin misiniz?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Evet",
        denyButtonText: `Hayır`,
        cancelButtonText: "İptal",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          var editedItem = this.items.find((i) => i.id === item.id);
          if (editedItem.isActive) {
            editedItem.isActive = 0;
          } else {
            editedItem.isActive = 1;
          }
        } else if (result.isDenied) {
          Swal.fire("Değişiklik kaydedilmedi", "", "info");
        }
      });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    openDeleteDoc() {
      this.$bvModal.show("modal-deleteOperationbelge");
    },
    editDocs(item) {
      this.editDocItem = item;
      this.addFileModal1 = true;
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
};
</script>

<style lang="scss">
//  Delete Operation style
</style>
